import React, { useContext } from 'react';
import convertObjectWithAstToReact from '@lmig/dotcom-aspect-helpers/convertObjectWithAstToReact';
import { ContextPage } from '@lmig/dotcom-aspect-components/ContextPage';
import ErrorDisplay from '@lmig/dotcom-aspect-components/ErrorDisplay';

/**
 * HoC component to simplify the handling of restricted content as well as full page-level
 * restriction by outputting a generic component handling all necessary restrictions as fallback.
 *
 * @see https://aspect-playbook-development.us-east-1.np.paas.lmig.com/#/component-api/components/WithPreprocessedContent
 * @param {React.ReactElement} Page
 */
const withPreprocessedContent = (Page) => ({ error, ...rest }) => {
  const contentContextValues = useContext(ContextPage);

  if (!contentContextValues) {
    throw new Error('`withPreprocessContent needs to be within a <PageContextProvider>');
  }

  if (error) {
    return <ErrorDisplay statusCode={500} err={error} />;
  }

  const {
    content: { meta = {}, ...inputContentProps },
  } = contentContextValues;

  const convertedInputContent = convertObjectWithAstToReact(inputContentProps);

  return <Page {...rest} content={{ meta, ...convertedInputContent }} />;
};

export default withPreprocessedContent;
