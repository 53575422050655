/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, CheckboxGroup, IconAuto, IconHome, IconCondo, IconRenters, IconValuables, IconLife, IconMotorcycle, IconPet, IconRV, IconWatercraft, IconUmbrella, IconCoverages, Link, Button } from '@lmig/lmds-react';
import LinkEnvironmentalize from "./LinkEnvironmentalize";
import styles from "./QuoteProducts.module.scss";

const QuoteProducts = ({ onChangeFunc = (() => { }), hasShowMore = false, selectedLobsPassed = {}, useDefaultLobs = false }) => {
  const defaultLobs = {
    auto: false,
    home: false,
    condo: false,
    renters: false,
    jewelry: false,
    life: false,
    motorcycle: false,
    pet: false,
    rv: false,
    watercraft: false,
    umbrella: false,
    other: false,
  };
  const [selectedLobs, setSelectedLobs] = useState(defaultLobs);
  const [showMore, setShowMore] = useState(false);
  const [seeMoreText, setSeeMoreText] = useState("+ See more products");
  const [lobHref, setLobHref] = useState([]);

  useEffect(() => {
    if (useDefaultLobs) {
      setSelectedLobs(defaultLobs);
    } else {
      setSelectedLobs(selectedLobsPassed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickLink = () => {
    if (showMore) {
      setSeeMoreText('+ See more products');
      setShowMore(false);
    } else {
      setSeeMoreText('- See fewer products');
      setShowMore(true);
    }
  };

  const handleLobSelect = (lob) => {
    const lobVal = selectedLobs[lob];
    const selectedLobsCopy = { ...selectedLobs };

    if (lobVal) {
      selectedLobsCopy[lob] = false;
    } else {
      selectedLobsCopy[lob] = true;
    }

    setSelectedLobs(selectedLobsCopy);

    let lobHrefCopy = [...lobHref];

    Object.keys(selectedLobsCopy).forEach((val) => {
      if (selectedLobsCopy[val] && !lobHrefCopy.includes(val)) {
        lobHrefCopy.push(val);
      } else if (!selectedLobsCopy[val]) {
        lobHrefCopy = lobHrefCopy.filter(item => item !== val);
      }
    });

    setLobHref(lobHrefCopy);
  };

  return (<div className={hasShowMore && styles.quoteWrapper}>
    <CheckboxGroup onChange={(e) => useDefaultLobs ? handleLobSelect(e.target.value) : onChangeFunc(e.target.value)}>
      <div className={hasShowMore ? styles.lobSelectMore : styles.lobSelect}>
        <label className={selectedLobs.auto ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
          <IconAuto size="24" className={styles.iconStyles} />
          <div className={selectedLobs.auto ? styles.selectedLabel : styles.unselectedLabel}>Auto</div>
          <Checkbox defaultChecked={selectedLobs.auto} id="autoSelect" className={styles.checkBoxButtonLmds} value="auto" data-testid="lob-auto" />
        </label>
        <label className={selectedLobs.home ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
          <IconHome size="24" className={styles.iconStyles} />
          <div className={selectedLobs.home ? styles.selectedLabel : styles.unselectedLabel}>Home</div>
          <Checkbox defaultChecked={selectedLobs.home} id="homeSelect" className={styles.checkBoxButtonLmds} value="home" data-testid="lob-home" />
        </label>
        <label className={selectedLobs.life ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
          <IconLife size="24" className={styles.iconStyles} />
          <div className={selectedLobs.life ? styles.selectedLabel : styles.unselectedLabel}>Life</div>
          <Checkbox defaultChecked={selectedLobs.life} id="lifeSelect" className={styles.checkBoxButtonLmds} value="life" data-testid="lob-life" />
        </label>
        <label className={selectedLobs.pet ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
          <IconPet size="24" className={styles.iconStyles} />
          <div className={selectedLobs.pet ? styles.selectedLabel : styles.unselectedLabel}>Pet</div>
          <Checkbox defaultChecked={selectedLobs.pet} id="petSelect" className={styles.checkBoxButtonLmds} value="pet" data-testid="lob-pet" />
        </label>
        {(!hasShowMore || showMore) && <>
          <label className={selectedLobs.condo ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
            <IconCondo size="24" className={styles.iconStyles} />
            <div className={selectedLobs.condo ? styles.selectedLabel : styles.unselectedLabel}>Condo</div>
            <Checkbox defaultChecked={selectedLobs.condo} id="condoSelect" className={styles.checkBoxButtonLmds} value="condo" data-testid="lob-condo" />
          </label>
          <label className={selectedLobs.renters ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
            <IconRenters size="24" className={styles.iconStyles} />
            <div className={selectedLobs.renters ? styles.selectedLabel : styles.unselectedLabel}>Renters</div>
            <Checkbox defaultChecked={selectedLobs.renters} id="rentersSelect" className={styles.checkBoxButtonLmds} value="renters" data-testid="lob-renters" />
          </label>
          <label className={selectedLobs.jewelry ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
            <IconValuables size="24" className={styles.iconStyles} />
            <div className={selectedLobs.jewelry ? styles.selectedLabel : styles.unselectedLabel}>Jewelry</div>
            <Checkbox defaultChecked={selectedLobs.jewelry} id="jewelrySelect" className={styles.checkBoxButtonLmds} value="jewelry" data-testid="lob-jewelry" />
          </label>
          <label className={selectedLobs.motorcycle ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
            <IconMotorcycle size="24" className={styles.iconStyles} />
            <div className={selectedLobs.motorcycle ? styles.selectedLabel : styles.unselectedLabel}>Motorcycle</div>
            <Checkbox defaultChecked={selectedLobs.motorcycle} id="motorcycleSelect" className={styles.checkBoxButtonLmds} value="motorcycle" data-testid="lob-motorcycle" />
          </label>
          <label className={selectedLobs.rv ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
            <IconRV size="24" className={styles.iconStyles} />
            <div className={selectedLobs.rv ? styles.selectedLabel : styles.unselectedLabel}>RV</div>
            <Checkbox defaultChecked={selectedLobs.rv} id="rvSelect" className={styles.checkBoxButtonLmds} value="rv" data-testid="lob-rv" />
          </label>
          <label className={selectedLobs.watercraft ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
            <IconWatercraft size="24" className={styles.iconStyles} />
            <div className={selectedLobs.watercraft ? styles.selectedLabel : styles.unselectedLabel}>Watercraft</div>
            <Checkbox defaultChecked={selectedLobs.watercraft} id="watercraftSelect" className={styles.checkBoxButtonLmds} value="watercraft" data-testid="lob-watercraft" />
          </label>
          <label className={selectedLobs.umbrella ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
            <IconUmbrella size="24" className={styles.iconStyles} />
            <div className={selectedLobs.umbrella ? styles.selectedLabel : styles.unselectedLabel}>Umbrella</div>
            <Checkbox defaultChecked={selectedLobs.umbrella} id="umbrellaSelect" className={styles.checkBoxButtonLmds} value="umbrella" data-testid="lob-umbrella" />
          </label>
          <label className={selectedLobs.other ? styles.checkBoxButtonSelected : styles.checkBoxButton}>
            <IconCoverages size="24" className={styles.iconStyles} />
            <div className={selectedLobs.other ? styles.selectedLabel : styles.unselectedLabel}>Other</div>
            <Checkbox defaultChecked={selectedLobs.other} id="otherSelect" className={styles.checkBoxButtonLmds} value="other" data-testid="lob-other" />
          </label></>}
      </div>
    </CheckboxGroup>
    {hasShowMore &&
      <div className={styles.showMoreArea}>
        <Link variant='standalone' caretPosition='none' data-testid="show-more" onClick={() => clickLink()}>{seeMoreText}</Link>
        <LinkEnvironmentalize attribution>
          <div data-theme="evolved">
            <Button rel="nofollow" href={lobHref.length > 0 ? `/quote?lob=${lobHref.join("&lob=")}` : '/quote'} variant="secondary" size="small" className={styles.startQuote} data-testid="startQuote">
              Start your quote online
            </Button>
          </div>
        </LinkEnvironmentalize>
      </div >}
  </div >);
};

QuoteProducts.propTypes = {
  onChangeFunc: PropTypes.func,
  hasShowMore: PropTypes.bool,
  selectedLobsPassed: PropTypes.object,
  useDefaultLobs: PropTypes.bool
};

export default QuoteProducts;